import React from 'react'
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaTimes, FaCcDinersClub, FaCcDiscover, FaCcJcb } from 'react-icons/fa';
import { BillingAPI } from "../lib/ladder"
import { toast } from 'react-toastify'
import UserManager from "../lib/userManager"

export default function PaymentMethodsList({entity, uploadData}) {
  const authToken = UserManager.getAuthToken()

  if (!entity) return(<></>)

  const getCardIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'visa':
        return <FaCcVisa size={24} />;
      case 'mastercard':
        return <FaCcMastercard size={32} />;
      case 'amex':
        return <FaCcAmex size={24} />;
      case 'diners':
        return <FaCcDinersClub size={24} />;
      case 'discover':
        return <FaCcDiscover size={24} />;
      case 'jcb':
        return <FaCcJcb size={24} />;
      default:
        return null;
    }
  };

  const onDelete = async (stripe_payment_method_id) => {
    const data = {
      stripe_payment_method_id: stripe_payment_method_id,
      entity_identifier: entity.identifier
    }

    const res = await BillingAPI.deletePaymentMethod(authToken, data)
    
    if (res.success) {
      toast.success('Successful deletion of payment type')
      uploadData()
    } else {
        toast.error(res.errors.join(', '))
    }
  }

  if (entity.payment_methods.length === 0) {
    return (
      <div className="mt-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="card">
              <div className="card-body text-center">
                <h5 className="card-title">You don't have payment types yet</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="card">
            <div className="card-header">Payment Types</div>
            <div className="card-body">
              <ul className="list-group">
                {entity.payment_methods.map((card) => (
                  <li key={card.id} className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-1">
                        {getCardIcon(card.brand)}
                      </div>
                      <div className="col-md-6">
                        <strong>{card.type}</strong>
                        <p className="mb-0">**** **** **** {card.last4}</p>
                      </div>
                      <div className="col-md-3">
                        <p className="mb-0">Expiry Date: {card.exp_month}/{card.exp_year}</p>
                      </div>
                      <div className="col-md-2">
                        <button className="btn btn-danger" onClick={() => onDelete(card.stripe_payment_method_id)}>
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
