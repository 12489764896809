import React, { useEffect, useState } from "react";
import styles from "./SubscriptionsCards.module.css"
import { BillingAPI } from "../lib/ladder"
import { toast } from 'react-toastify'

import UserManager from "../lib/userManager"
import ToastManager from "../lib/toastManager"

export default function SubscriptionsCards({entity, uploadData}) {
  const authToken = UserManager.getAuthToken()

  let allSubscriptions = [
    {
      title: 'Organization',
      plan: 'organization',
      abilities: [
        'All your employers will have the opportunity to post jobs for free.'
      ],
      cost: 1500
    },
    {
      title: 'Pro',
      plan: 'pro',
      abilities: [
        'On-platform messaging',
        'Profile Search',
        '$99 per job post per month'
      ],
      cost: 199 
    },
    {
      title: 'Organization Pro',
      plan: 'organization_pro',
      abilities: [
        'On-platform messaging',
        'Profile Search',
        'Free job posts'
      ],
      cost: 99 
    },
    {
      title: 'Trial Pro',
      plan: 'trial_pro',
      abilities: [
        'Free trial period 14 days',
        'No credit cards required',
        'On-platform messaging',
        'Profile Search',
        '$99 per job post per month'
      ],
      cost: 'Free'
    },
    {
      title: 'Trial Organization Pro',
      plan: 'trial_organization_pro',
      abilities: [
        'Free trial period 14 days',
        'No credit cards required',
        'On-platform messaging',
        'Profile Search',
        'Free job posts'
      ],
      cost: 'Free'
    },
    {
      title: 'Basic',
      plan: 'basic',
      abilities: [
        '$99 per job post per month'
      ],
      cost: 'Free'
    },
    {
      title: 'Organization Basic',
      plan: 'organization_basic',
      abilities: [
        'Free job posts per month'
      ],
      cost: 'Free'
    }, 
  ]

  if (!entity && !uploadData) return(<></>)

  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [availableSubscriptions, setAvailableSubscriptions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setCurrentSubscription(allSubscriptions.find(sub => sub.title === entity.plan))
    setAvailableSubscriptions(allSubscriptions.filter(sub => entity.available_subscriptions.includes(sub.plan)))
  }, [entity])

  if (!currentSubscription || !availableSubscriptions) return(<></>)

  const onChange = async (plan) => {
    setIsLoading(true)

    const data = {
      plan: plan,
      entity_identifier: entity.identifier
    }
    const res = await BillingAPI.changePlan(authToken, data)
    
    if (res.success) {
      toast.success('Successful, thank you for using Ladder')
      uploadData()
    } else {
      ToastManager.errorToast(res.errors?.join(", "))
    }

    setIsLoading(false)
  }

  const handleCostOfSubscription = (subscription) => {
    if (typeof subscription.cost === 'number') {
      return(<h1 className={"card-title pricing-card-title"}>${subscription.cost}<small className={"text-body-secondary fw-light"}>/mo</small></h1>)
    }
    
    return(<h1 className={"card-title pricing-card-title"}>{subscription.cost}</h1>)
  }

  const buildTitle = (title) => {
    switch (title) {
      case 'Organization Pro':
        return('Pro')
      case 'Organization Basic':
        return('Basic')
      case 'Trial Organization Pro':
        return('Pro - Free Trial')
      default:
        return(title)
    }
  }

  const buildCurrentSubscription = () =>  {
    return(
      <div className={"col-sm-12 d-flex " + (availableSubscriptions.length === 0 ? 'col-md-12' : 'col-md-6')}>
        <div className={"card mb-4 rounded-3 shadow-sm flex-fill " + styles['border-primary']}>
          <div className={"card-header"}>
            <h4 className={"my-0 fw-normal"}>{buildTitle(currentSubscription.title)}</h4>
          </div>
          <div className={"card-body  d-flex flex-column"}>
            {handleCostOfSubscription(currentSubscription)}
            <ul className="list-unstyled mt-3 mb-4 flex-grow-1">
              {currentSubscription.abilities.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <div className="mt-auto">
              <button type="button" className={"mt-auto btn btn-lg btn-primary " + styles['btn-current']} disabled/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const buildAvailableSubscriptions = () => {
    return(
      <>
        {availableSubscriptions.map((subscription, indexS) => (
          <div key={indexS} className={"col-sm-12 col-md-6 d-flex"}>
            <div className={"card mb-4 rounded-3 shadow-sm flex-fill"}>
              <div className={"card-header text-bg-primary"}>
                <h4 className={"my-0 fw-normal"}>{buildTitle(subscription.title)}</h4>
              </div>
              <div className={"card-body d-flex flex-column"}>
                {handleCostOfSubscription(subscription)}
                <ul className="list-unstyled mt-3 mb-4 flex-grow-1">
                  {subscription.abilities.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <div className="mt-auto">
                  <button
                    type="button"
                    className={"mt-auto btn btn-lg btn-primary"}
                    onClick={() => onChange(subscription.plan)}
                    disabled={isLoading}
                    >Change</button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }

  return(<>
    <h3 className={"text-center"}>Subscriptions</h3>
    <div className={"row text-center"}>
      {buildCurrentSubscription()}
      {buildAvailableSubscriptions()}
    </div>
  </>)
}
