import React from 'react'

export default function PaymentsList({entity}) {
  if (!entity) return(<></>)

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'succeeded':
        return 'text-success'
      case 'processing':
        return 'text-warning'
      case 'canceled':
        return 'text-danger'
      default:
        return ''
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    return new Date(dateString).toLocaleDateString('en-US', options)
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (entity.payments.length === 0) {
    return (
      <div className="mt-5">
        <h3 className={"text-center"}>Transactions</h3>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="card">
              <div className="card-body text-center">
                <h5 className="card-title">You don't have payments yet</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5">
      <h3 className={"text-center"}>Transactions</h3>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="card">
            <div className="card-header">Payment List</div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                {entity.payments.map((payment, index) => (
                  <li key={index} className="list-group-item">
                    <div className="row">
                      <div className="col-md-4">
                        <p><strong>Amount:</strong> ${payment.charge_cents_amount / 100}</p>
                      </div>
                      <div className="col-md-4">
                        <p><strong>Status:</strong> <span className={getStatusColor(payment.status)}>{capitalizeFirstLetter(payment.status)}</span></p>
                      </div>
                      <div className="col-md-4">
                        <p><strong>Date:</strong> {formatDate(payment.created_on_stripe)}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
