import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { BillingAPI } from '../lib/ladder'
import { toast } from 'react-toastify';
import UserManager from "../lib/userManager"

export default function StripeForm({entityIdentifier, uploadData}) {
  const authToken = UserManager.getAuthToken();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const makeAttach = async (paymentMethod) => {
    const data = {
      stripe_payment_method_id: paymentMethod.id,
      entity_identifier: entityIdentifier
    }
    const res = await BillingAPI.attach(authToken, data)
    
    if (res.success) {
      toast.success('You have successfully added a new payment type')
      elements.getElement(CardElement).clear()
      uploadData()
    } else {
      toast.error('There is something wrong with your card')
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      return;
    }


    makeAttach(paymentMethod)   
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ display: 'block', marginBottom: '30px' }}>Add Payment Type</label>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#444',
                '::placeholder': {
                  color: '#777',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
      {error && <div style={{ color: '#9e2146', marginBottom: '10px' }}>{error}</div>}
      <button type="submit" className={'btn btn-primary btn-block mt-3'} disabled={!stripe}>
        Submit Card
      </button>
    </form>
  )
}
