import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
  
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_API_KEY);
  
export default function StripeForm({entity, uploadData}) {
  if (!entity) return(<></>)
  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentForm entityIdentifier={entity.identifier} uploadData={uploadData}/>
      </Elements>
    </>
  )
}
