import React from 'react'
import SubscriptionsCards from './SubscriptionsCards'
import StripeForm from './StripeForm'
import PaymentMethodsList from './PaymentMethodsList'
import PaymentsList from './PaymentsList'

export default function Billing({entity, uploadData, showSubscriptionCards, showPayments}) {
  
  return(
    <div>
      <h2 className="box-title">Billing</h2>
      { showSubscriptionCards ? <SubscriptionsCards entity={entity} uploadData={uploadData}/> : ''}
      <hr />
      <StripeForm entity={entity} uploadData={uploadData} />
      <PaymentMethodsList entity={entity} uploadData={uploadData} />
      <hr />
      { showPayments ? <PaymentsList entity={entity}/> : '' }
    </div>
  )
}
